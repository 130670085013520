import React from "react"
import Layout from "../components/layout"
import ContentInfo from "../components/ContentInfo"
import ArticleLink from "../components/ArticleLink"
import { graphql } from "gatsby"
import ReadMoreLink from "../components/ReadMoreLink"
import AuthorAvatar from "../components/AuthorAvatar"
import { Github } from "@styled-icons/boxicons-logos/Github"
import { FacebookCircle } from "@styled-icons/boxicons-logos/FacebookCircle"

const AuthorTemplate = ({ data }) => {
  const user = data.strapiUser
  return (
    <Layout>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <AuthorAvatar author={user}/>
        <h2>{user.username}</h2>
        {
          user?.GithubLink
          &&
          <span style={{ cursor: "pointer", marginLeft: 10 }}>
            <Github height={35}
                    color={"red"}
                    title={user.GithubLink}
                    onClick={() => {
                      window.open(user.GithubLink)
                    }}/>
          </span>
        }
        {
          user?.fbLink &&
          <span style={{ cursor: "pointer", marginLeft: 10 }}>
            <FacebookCircle
              height={35}
              color={"red"}
              title={user.fbLink}
              onClick={() => {
                window.open(user.fbLink)
              }}/>
          </span>
        }
      </div>
      <div>
        {user.articles.map(article => {
          return (
            <div key={article.id} style={{ marginBottom: 50 }}>
              <ArticleLink article={article}/>
              <ContentInfo article={article}/>

              <ReadMoreLink article={article}/>
              <hr/>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}
export default AuthorTemplate

export const query = graphql`
    query ($id: String!) {
        strapiUser(id: {eq: $id}) {
            id
            username
            articles {
                id
                title
                content
            }
            avatar {
                publicURL
            }
            GithubLink
            fbLink
        }
    }
`
