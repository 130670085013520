import { Link } from "gatsby"
import React from "react"
import slugVi from "../utils/slugVi"

export default ({ article }) => {
  const link = slugVi(article.title)

  return (
    <h2>
      <Link to={`/post/${link}`}>{article.title}</Link>
    </h2>
  )
}
